import moment from "moment";

export default {
  type: "subscriptions",
  start_time: moment().toDate(),
  end_time: moment().add(99, 'years').toDate(),
  relationshipNames: ["organization", "package"],
  organization: {
    type: "organizations",
    id: null,
  },
  package: {
    type: "packages",
    id: null,
  },
};
