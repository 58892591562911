<template>
  <div class="kw-tabs-wrapper-content">
    <h3>
      {{ $t("COMMON.SUBSCRIPTIONS") }}
    </h3>
    <subscription-list-table
      :filterOrganization="organization.id"
      @onViewSubscription="openSubscriptionViewModal"
      @onEditSubscription="openSubscriptionEditModal"
    />
  </div>
</template>

<script>
import { QUERY_ACTIONS_VIEW, QUERY_ACTIONS_EDIT } from "@/constants/common";
import SubscriptionListTable from "@/views/Pages/ResellerModule/SubscriptionManagement/partials/SubscriptionListTable.vue";

export default {
  name: "organization-view-subscriptions",

  components: { SubscriptionListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    openSubscriptionViewModal(subscription) {
      this.$router.push({
        name: "List Subscriptions",
        query: { id: subscription.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openSubscriptionEditModal(subscription) {
      this.$router.push({
        name: "List Subscriptions",
        query: { id: subscription.id, action: QUERY_ACTIONS_EDIT },
      });
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
