<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.ROLES") }}</h3>
    <role-list-table
      :filterOrganization="organization.id"
      @onViewRole="openRoleViewModal"
      @onEditRole="openRoleEditModal"
      @onDeleteRole="openRoleViewModal"
    />
  </div>
</template>

<script>
import { QUERY_ACTIONS_VIEW, QUERY_ACTIONS_EDIT } from "@/constants/common";
import RoleListTable from "../../RoleManagement/partials/RoleListTable.vue";

export default {
  name: "organization-view-roles",

  components: { RoleListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    openRoleViewModal(role) {
      this.$router.push({
        name: "List Roles",
        query: { id: role.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openRoleEditModal(role) {
      this.$router.push({
        name: "List Roles",
        query: { id: role.id, action: QUERY_ACTIONS_EDIT },
      });
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
