<template>
  <div class="kw-tabs-wrapper-content">
    <h3>
      {{ $t("ORGANIZATIONS.AUTHORIZATIONS") }}
    </h3>
    <permissions-table
      :permissionsData="organization.activePermissions"
      :organization="organization"
    />
  </div>
</template>

<script>
import PermissionsTable from "@/components/PermissionsTable.vue";

export default {
  name: "organization-view-authorizations",

  components: { PermissionsTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
