<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.USERS") }}</h3>
    <user-list-table
      :filterOrganization="organization.id"
      @onViewUser="openUserViewModal"
      @onEditUser="openUserEditModal"
      @onDeleteUser="openUserViewModal"
    />
  </div>
</template>

<script>
import { QUERY_ACTIONS_VIEW, QUERY_ACTIONS_EDIT } from "@/constants/common";
import UserListTable from "../../UserManagement/partials/UserListTable.vue";

export default {
  name: "organization-view-users",

  components: { UserListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    openUserViewModal(user) {
      this.$router.push({
        name: "List Users",
        query: { id: user.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openUserEditModal(user) {
      this.$router.push({
        name: "List Users",
        query: { id: user.id, action: QUERY_ACTIONS_EDIT },
      });
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
