<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="options"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              :label="$t('COMMON.ORGANIZATION')"
              prop="organization"
              min-width="120"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('OPTIONS.MODULE')"
              prop="module"
              sortable="custom"
              min-width="120"
            >
              <template v-slot="{ row }">
                {{ $t(`OPTIONS.MODULE_${row.module}`) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('OPTIONS.NAME')"
              prop="name"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $t(`OPTIONS.OPTION_${row.name}`) }}
              </template>
            </el-table-column>
            <el-table-column
              min-width="500"
              :label="$t('OPTIONS.VALUE')"
              prop="name"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <option-form :option="row" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="options.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import requestErrorMixin from "@/mixins/request-error-mixin";
import OptionForm from "./OptionForm.vue";

export default {
  name: "option-list-table",

  components: {
    BasePagination,
    OptionForm,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "name",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500, 1000],
      },
      options: [],
      loading: true,
      selectedOrganization: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            hide_option: false,
            // disabled: false,
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        await this.$store.dispatch("options/list", params);
        this.options = this.$store.getters["options/list"];
        this.total = this.$store.getters["options/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewOption(option) {
      this.$router.push({
        name: "View Option",
        params: { id: option.id },
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
